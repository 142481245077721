import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import { hyphenate } from "../utils/urls"

const CategoryPage = ({ pageContext }) => {
  const { categories } = pageContext

  if (!categories) return null

  return (
    <Layout>
      <SEO title="Category Archives" path="/blog/category/" />
      <Container>
        <h1>Category Archives</h1>
        <ul>
          {categories.map((category, index) => {
            const url = `/blog/category/${hyphenate(category)}/`

            return (
              <li key={index}>
                <Link to={url}>{category}</Link>
              </li>
            )
          })}
        </ul>
      </Container>
    </Layout>
  )
}

export default CategoryPage
